/*
  ColorPalette.js
  Betterment Labs 
  Created by Charles Major on 02/01/18. 
  Copyright © 2018 Betterment Labs, LLC. All rights reserved.

  Use :
*/

const ColorPalette = {
  Black: "#0A0203",
  SOSYellow: "#FCD50B",
  BettermentYellow: "#F8D269",
  Purple: "#5A2154",
  Red: "#B21E27",
  Cream: "#FBF4CB",
  BarelyGray: "#c6c8c77a",
  LightGray: "#C6C8C7",
  DarkGray: "#434246",
  LightBlue: "#7793F3",
  MidBlue: "#332D8C",
  SOSDarkBlue: "#0d0047",
  priorityOrange: "#FF9812",
  BettermentDarkBlue: "#2B2F40",
  White: "#FFFFFF",
  Teal: "#00cdcd",
  Green: "#2E8B57"
};

export default ColorPalette;
