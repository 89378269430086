/*
  GenericStyledComponents.js
  Betterment Labs
  Created by Matthew Peterson on 06/20/18.
  Copyright © 2018 Betterment Labs, LLC. All rights reserved.
*/

import styled, { css } from "styled-components";
import SASStyleGuide from "../theming/SASStyleGuide.js";
import ColorPalette from "../theming/ColorPalette.js";

// const defaultFont = SASStyleGuide.font.family.chivo;
const headerFont = SASStyleGuide.font.family.chivo;

const sizes = {
  cardsToSmall: 1210,
  giant: 1170,
  desktop: 1100,
  oneThousand: 1000,
  nineHundred: 900,
  eightHundred: 800,
  tablet: 768,
  phone: 415
};

export const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16;
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)}
    }
  `;
  return accumulator;
}, {});

export const SASLoader = styled.div`
  border: 16px solid ${props => props.mainColor || SASStyleGuide.color.bodyText};
  border-top: 16px solid ${props => props.spinColor || SASStyleGuide.color.title};
  border-radius: 50%;
  width: ${props => props.width || "100px"};
  height: ${props => props.height || "100px"};
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SASDiv = styled.div`
  display: ${props => props.display || "flex"};
  position: ${props => props.position};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  flex-direction: ${props => props.flexDirection || "column"};
  justify-content: ${props => props.justifyContent || "center"};
  flex-wrap: ${props => props.flexWrap || null};
  flex: ${props => props.flex};
  align-items: ${props => props.alignItems || "center"};
  align-self: ${props => props.alignSelf || null};
  height: ${props => (props.height ? props.height : "100%")};
  min-height: ${props => props.minHeight || null};
  max-height: ${props => props.maxHeight || null};
  width: ${props => props.width || "100%"};
  min-width: ${props => props.minWidth || null};
  max-width: ${props => props.maxWidth || null};
  background-color: ${props => props.backgroundColor || null};
  background: ${props => props.background};
  background-image: url(${props => props.backgroundImage || null});
  background-repeat: ${props => props.backgroundRepeat || "no-repeat"};
  background-attachment: ${props => props.backgroundAttachment || null};
  background-position: ${props => props.backgroundPosition || "center center"};
  background-size: ${props => props.backgroundSize || null};
  border: ${props => props.border};
  border-top: ${props => props.borderTop || null};
  border-bottom: ${props => props.borderBottom || null};
  border-right: ${props => props.borderRight || null};
  overflow: ${props => props.overflow};
  border-left: ${props => props.borderLeft || null};
  opacity: ${props => props.opacity || null};
  box-shadow: ${props => props.boxShadow || null};
  border-left: ${props => props.borderLeft || null};
  border-right: ${props => props.borderRight || null};
  border-radius: ${props => props.borderRadius || null};
  order: ${props => props.order};
  z-index: ${props => props.zIndex || null};
  overflow: ${props => props.overflow};
  top: ${props => props.top};
  left: ${props => props.left};
  bottom: ${props => props.bottom};
  right: ${props => props.right};

  &:hover {
    transform: ${props => props.transformation || null};
    background: ${props => props.hoverBackgroundColor};
    filter: ${props => props.hoverBrightness || null};
    background-size: ${props => props.hoverBackgroundSize};
    cursor: ${props => props.cursor};
    border-radius: ${props => props.hoverBorderRadius};
    color: ${props => props.hoverColor};
  }

  @media (max-width: mediaQueryMediumMaxWidth) {
    height: ${props => props.tabletHeight || props.height};
    background: ${props => props.backgroundColor},
      url(${props => props.mobileBackground || props.background})
        ${props => props.backgroundRepeat} ${props => props.backgroundAttachment}
        ${props => props.backgroundPosition};
    flex-direction: ${props => props.smallTabletFlexDirection || "column"};
  }

  @media (max-width: mediaQuerySmallMaxWidth) {
    height: ${props => props.mobileHeight || props.height};
    background-position: top center;
  }
`;

export const ScrollDiv = styled.div`
  padding-bottom: ${props => props.paddingBottom || "30px"};
  width: ${props => props.width};
  margin: ${props => props.margin};
  height: ${props => props.height};
  opacity: ${props => props.opacity};
  background-color: ${props => props.backgroundColor};
  padding: ${props => props.padding};
  display: block;
  overflow-y: ${props => props.overflowY || "scroll"};
  overflow-x: ${props => props.overflowX};
  max-height: ${props => props.maxHeight};

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.trackColor || SASStyleGuide.color.background};
    border: 2px solid ${props => props.thumbColor || SASStyleGuide.color.bodyText};
    border-radius: 5px 0 0 5px;
    /* outline: 1px solid slategrey; */
  }
`;

export const SASForm = styled.form`
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  width: ${props => props.width || null};
  height: ${props => props.height || null};
  margin: ${props => props.margin || null};
  align-items: ${props => props.alignItems || "center"};
  justify-content: ${props => props.justifyContent || "center"};
  overflow: ${props => props.overflow};
`;

export const SASTable = styled.table`
  width: ${props => props.width || null};
  margin: ${props => props.margin || null};
  background-color: ${props => props.backgroundColor || null};
  border-collapse: ${props => props.borderCollapse || "collapse"};
  overflow: ${props => props.overflow};
`;

export const SASTableCell = styled.td`
  white-space: ${props => props.whiteSpace};
  overflow: ${props => props.overflow};
  font-size: ${props => props.fontSize};
  font-family: ${props => props.fontFamily || headerFont};
  font-weight: ${props => props.fontWeight};
  display: ${props => props.display};
  padding: ${props => props.padding || "10px"};
  flex-direction: ${props => props.flexDirection};
  text-align: ${props => props.textAlign || "center"};
  border: ${props => props.border};
  border-top: ${props => props.borderTop};
  border-right: ${props => props.borderRight};
  border-left: ${props => props.borderLeft};
  border-bottom: ${props => props.borderBottom};
`;

export const SASTableData = styled.td`
  text-align: ${props => props.textAlign || "center"};
  padding: ${props => props.padding || "10px"};
  color: ${props => props.color};
  border-left: ${props => props.borderLeft};
  border-right: ${props => props.borderRight};
  border-top: ${props => props.borderTop};
  border-bottom: ${props => props.borderBottom};
`;

export const SASTableRow = styled.tr`
  background-color: ${props => props.backgroundColor || SASStyleGuide.color.tertiary};
  height: ${props => props.height};
  border-top: ${props => props.borderTop};
  border-right: ${props => props.borderRight};
  border-left: ${props => props.borderLeft};
  border-bottom: ${props => props.borderBottom};
`;

export const SASTextArea = styled.textarea`
  height: ${props => props.height || null};
  width: ${props => props.width || null};
  resize: ${props => props.resize || "auto"};
`;

export const SASTableHeader = styled.th`
  white-space: ${props => props.whiteSpace};
  border: 1px solid ${props => props.borderColor || SASStyleGuide.color.bodyText};
  border-top: ${props => props.borderTop};
  border-right: ${props => props.borderRight};
  display: ${props => props.display};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  border-left: ${props => props.borderLeft};
  border-bottom: ${props => props.borderBottom};
  font-size: ${props => props.fontSize || SASStyleGuide.font.size.tiny};
  color: ${props => props.color || SASStyleGuide.color.background};
  padding: ${props => props.padding || null};
  font-weight: ${props => props.fontWeight || SASStyleGuide.font.weight.sevenHundred};
  height: ${props => props.height || null};

  &:hover {
    cursor: ${props => props.cursor};
    background-color: ${props => props.hoverBackgroundColor};
  }
`;

export const SASSelect = styled.select`
  border: ${props => props.border || "none"};
  width: ${props => props.width || "100px"};
  height: ${props => props.height || "25px"};
`;

export const SASInput = styled.input`
  border-radius: ${props => props.borderRadius};
  border: ${props => props.border || "none"};
  border-bottom: ${props => props.borderBottom || "none"};
  height: ${props => props.heigth || "25px"};
  width: ${props => props.width || null};
  text-align: ${props => props.textAlign || "center"};
`;

export const SASHeader = styled.h1.attrs({
  width: props => props.width || null,
  margin: props => props.margin || "0px",
  color: props => props.color || SASStyleGuide.color.bodyText,
  padding: props => props.padding || "0 2%",
  fontFamily: props => props.fontFamily || headerFont,
  fontWeight: props => props.fontWeight || SASStyleGuide.font.weight.fiveHundred,
  fontSize: props => props.fontSize || SASStyleGuide.font.size.large
})`
  width: ${props => props.width || "100%"};
  text-align: ${props => props.textAlign || "center"};
  margin: ${props => props.margin};
  color: ${props => props.color};
  padding: ${props => props.padding};
  font-family: ${props => props.fontFamily || headerFont};
  font-size: ${props => props.fontSize || SASStyleGuide.font.size.large};
  font-weight: ${props => props.fontWeight};
  font-style: ${props => props.fontStyle};
  position: ${props => props.position};
  left: ${props => props.left};
  top: ${props => props.top};

  &:hover {
      color: ${props => props.hoverColor || null};
      cursor: ${props => props.cursorType || null};
  }

  ${media.desktop`font-size: ${props => props.tabletFontSize || null};`}
  ${media.tablet`font-size: ${props => props.smallTabletSize || null};`}
  ${media.phone`font-size: ${props => props.mobileFontSize || null};`}
`;

export const SASSectionHeader = styled.h2`
  height: ${props => props.height};
  width: ${props => props.width || "fit-content"};
  text-align: ${props => props.textAlign || "center"};
  margin: ${props => props.margin || "0px"};
  color: ${props => props.color || SASStyleGuide.color.bodyText};
  padding: ${props => props.padding || "0 2%"};
  font-family: ${props => props.fontFamily || headerFont};
  font-style: ${props => props.fontStyle};
  font-size: ${props => props.fontSize || SASStyleGuide.font.size.medium};
  font-weight: ${props => props.fontWeight || SASStyleGuide.font.weight.fiveHundred};
  white-space: ${props => props.whiteSpace};
  line-height: ${props => props.lineHeight || "initial"};
  text-decoration: ${props => props.textDecoration};

  &:hover {
    color: ${props => props.hoverColor || null};
    cursor: ${props => props.cursorType || null};
}

  ${media.desktop`font-size: ${props => props.tabletFontSize || null};`}
  ${media.tablet`font-size: ${props => props.smallTabletFontSize || null};`}
  ${media.phone`font-size: ${props => props.mobileFontSize || null};`}
`;

export const SASParagraph = styled.p.attrs({
  width: props => props.width || "100%",
  height: props => props.height || "100%",
  margin: props => props.margin || "0px",
  color: props => props.color || SASStyleGuide.color.background,
  padding: props => props.padding || "0 2%",
  fontSize: props => props.fontSize || SASStyleGuide.font.size.small,
  fontFamily: props => props.fontFamily || headerFont,
  border: props => props.botder || null
})`
  border: ${props => props.border};
  width: ${props => props.width};
  margin: ${props => props.margin};
  text-align: ${props => props.textAlign || "left"};
  color: ${props => props.color};
  text-decoration: ${props => props.textDecoration};
  font-style: ${props => props.fontStyle || "initial"};
  padding: ${props => props.padding};
  font-family: ${props => props.fontFamily || headerFont};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  text-align: ${props => props.textAlign};
  line-height: ${props => props.lineHeight || "1"};
  white-space: ${props => props.whiteSpace || "pre-line"};

  &:hover {
    color: ${props => props.hoverColor || null};
    cursor: ${props => props.cursorType || null};
}

  ${media.desktop`
    font-size: ${props => props.tabletFontSize}; 
    text-align: ${props => props.deskotpTextAlign}
  `}
  ${media.tablet`
    font-size: ${props => props.smallTabletFontSize};
  `}
  ${media.phone`
    font-size: ${props => props.mobileFontSize};
  `}
`;

export const SASAnchor = styled.a`
  display: ${props => props.display};
  flex-direction: ${props => props.flexDirection};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  flex-wrap: ${props => props.flexWrap};
  flex: ${props => props.flex};
  height: ${props => props.height};
  width: ${props => props.width};
  margin: ${props => props.margin};
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  padding: ${props => props.padding};
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize || SASStyleGuide.font.size.mediumLarge};
  font-weight: ${props => props.fontWeight};
  text-decoration: ${props => props.textDecoration || "none"};
  text-align: ${props => props.textAlign};
  border: ${props => props.border};

  &:hover {
    color: ${props => props.hoverColor || SASStyleGuide.color.background};
    background-color: ${props => props.hoverBackgroundColor};
    cursor: pointer;
  }

  ${media.desktop`font-size: ${props =>
    props.tabletFontSize || SASStyleGuide.font.size.mediumLarge};`}
  ${media.tablet`font-size: ${props =>
    props.smallTabletFontSize || SASStyleGuide.font.size.medium};`}
  ${media.phone`font-size: ${props =>
    props.mobileFontSize || SASStyleGuide.font.size.mediumSmall};`}

`;

export const SASAnchorHomeNav = styled.a.attrs({
  margin: props => props.margin || "0px",
  color: props => props.color || SASStyleGuide.color.background,
  padding: props => props.padding || null,
  fontFamily: props => props.fontFamily || headerFont,
  fontSize: props => props.fontSize || SASStyleGuide.font.size.small,
  fontWeight: props => props.fontWeight || SASStyleGuide.font.weight.fiveHundred,
  textDecoration: props => props.textDecoration || "none",
  width: props => props.width || "100%",
  height: props => props.height || "100%",
  maxWidth: props => props.maxWidth || "100%",
  maxHeight: props => props.maxHeight || "100%",
  border: props => props.border || "1px solid white",
  textAlign: props => props.textAlign || "center"
})`
  margin: ${props => props.margin};
  color: ${props => props.color};
  padding: ${props => props.padding};
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  text-decoration: ${props => props.textDecoration};
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  height: ${props => props.maxHeight};
  max-height: ${props => props.width};
  border: ${props => props.border};
  text-align: ${props => props.textAlign};
  transition: all ease 0.8s;

  &:hover {
    border-radius: 100px;
    box-shadow: inset 560px 0 0 0 ${SASStyleGuide.color.darkBlue};
    color: ${SASStyleGuide.color.white};
  }
`;

export const SASCard = styled.a.attrs({
  display: props => props.display || "flex",
  border: props => props.border || null,
  borderRadius: props => props.borderRadius || "5px",
  padding: props => props.padding || "0 2%",
  margin: props => props.margin || "0px",
  flexDirection: props => props.flexDirection || "column",
  justifyContent: props => props.justifyContent || "center",
  alignItems: props => props.alignItems || "center",
  flex: props => props.flex || null,
  height: props => props.height || null,
  width: props => props.width || null,
  backgroundColor: props => props.backgroundColor || null,
  backgroundImage: props => props.desktopImage || null,
  backgroundRepeat: props => props.backgroundRepeat || "no-repeat",
  backgroundAttachment: props => props.backgroundAttachment,
  backgroundPosition: props => props.backgroundPosition,
  boxShadow: props =>
    props.boxShadow || `0px 1px 10px 1px ${SASStyleGuide.color.lightGray}`,
  textDecoration: props => props.textDecoration || "none"
})`
  display: ${props => props.display};
  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  flex-direction: ${props => props.flexDirection};
  justify-content: ${props => props.justifyContent};
  flex: ${props => props.flex};
  align-items: ${props => props.alignItems};
  height: ${props => props.height};
  width: ${props => props.width};
  background-color: ${props => props.backgroundColor};
  background-image: (${props => props.desktopImage});
  background-repeat: ${props => props.backgroundRepeat};
  background-attachment: ${props => props.backgroundAttachment};
  background-position: ${props => props.backgroundPosition};
  box-shadow: ${props => props.boxShadow};
  text-decoration: ${props => props.textDecoration};

  ${media.desktop`
    height: ${props => props.tabletHeight};
    background: ${props => props.backgroundColor},
      url(${props => props.mobileBackground})
      ${props => props.backgroundRepeat}
      ${props => props.backgroundAttachment}
      ${props => props.backgroundPosition}
    `}
  ${media.phone`
    height: ${props => props.mobileHeight};
    background-position: top center;
    `}
`;

export const FooterGridLink = styled.a.attrs({
  border: props => props.border || null,
  borderLeft: props => props.borderLeft || null,
  borderRight: props => props.borderRight || null,
  borderBottom: props => props.borderBottom || null,
  bordeTop: props => props.borderTop || null,
  width: props => props.width || "100%",
  margin: props => props.margin || "0px",
  color: props => props.color || SASStyleGuide.color.background,
  padding: props => props.padding || "1% 2%",
  fontFamily: props => props.fontFamily || headerFont,
  fontSize: props => props.fontSize || SASStyleGuide.font.size.small,
  fontWeight: props => props.fontWeight || SASStyleGuide.font.weight.threeHundred,
  textAlign: props => props.textAlign || "left",
  textDecoration: props => props.textDecoration || "none"
})`
  text-decoration: ${props => props.textDecoration};
  border: ${props => props.border};
  border-top: ${props => props.borderTop};
  border-bottom: ${props => props.borderBottom};
  border-left: ${props => props.borderLeft};
  border-right: ${props => props.borderRight};
  width: ${props => props.width};
  margin: ${props => props.margin};
  color: ${props => props.color};
  padding: ${props => props.padding};
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  text-align: ${props => props.textAlign};
  transition: all ease 0.8s;

  &:hover {
    background-color: ${SASStyleGuide.color.white};
    color: ${SASStyleGuide.color.black};
  }

  ${media.tablet`font-size: ${SASStyleGuide.font.size.tiny};
    border-bottom: none;`}
  ${media.phone`font-size: ${SASStyleGuide.font.size.finePrint};`}
`;

export const SASFooterAnchor = styled.a.attrs({
  height: props => props.height,
  width: props => props.width,
  display: props => props.display || "flex",
  flexDirection: props => props.flexDirection || "column",
  justifyContent: props => props.justifyContent || "center",
  alignItems: props => props.alignItems || "center",
  flexWrap: props => props.flexWrap || null,
  flex: props => props.flex || "wrap",
  backgroundColor: props => props.backgroundColor,
  margin: props => props.margin || "0px",
  color: props => props.color || SASStyleGuide.color.background,
  padding: props => props.padding || null,
  fontFamily: props => props.fontFamily || headerFont,
  fontSize: props => props.fontSize || SASStyleGuide.font.size.small,
  fontWeight: props => props.fontWeight || SASStyleGuide.font.weight.fiveHundred,
  textDecoration: props => props.textDecoration || "none",
  textAlign: props => props.textAlign || "center"
})`
  display: ${props => props.display};
  flex-direction: ${props => props.flexDirection};
  justify-content: ${props => props.justifyContent};
  flex-wrap: ${props => props.flexWrap};
  flex: ${props => props.flex};
  height: ${props => props.height};
  width: ${props => props.width};
  margin: ${props => props.margin};
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  padding: ${props => props.padding};
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  text-decoration: ${props => props.textDecoration};
  text-align: ${props => props.textAlign};

  &:hover {
    color: ${props => props.hoverColor || SASStyleGuide.color.background};
    background-color: ${props => props.hoverBackgroundColor};
    cursor: pointer;
  }

  ${media.desktop`font-size: ${SASStyleGuide.font.size.small};`}
  ${media.tablet`font-size: ${SASStyleGuide.font.size.smallTiny};`}
  ${media.phone`font-size:  ${SASStyleGuide.font.size.tiny};`}
`;

export const SASImage = styled.img`
  max-width: ${props => props.maxWidth || "64px"};
  max-height: ${props => props.maxHeight || "64px"};
  min-width: ${props => props.minWidth || null};
  min-height: ${props => props.minHeight || null};
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "100%"};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "0px")};
  box-shadow: ${props => props.boxShadow};
  padding: ${props => props.padding || null};
  margin: ${props => props.margin || null};

  ${media.desktop`height: ${props => props.tabletHeight};`}
  ${media.tablet`height: ${props => props.smallTabletHeight};`}
  ${media.phone`height: ${props => props.mobileHeight};`}
`;

export const SASButton = styled.button`
  height: ${props => props.height};
  width: ${props => props.width};
  background-color: ${props => props.backgroundColor || SASStyleGuide.color.background};
  color: ${props => props.color || SASStyleGuide.color.title};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  border: ${props => props.border || null};
  border-radius: ${props => props.borderRadius || "10px"};
  border-color: ${props => props.borderColor || null};
  font-size: ${props => props.fontSize || SASStyleGuide.font.size.mediumLarge};
  font-family: ${props => props.fontFamily || headerFont};
  font-weight: ${props => props.fontWeight};

  &:hover {
    color: ${props => props.hoverColor || SASStyleGuide.color.highlight};
    cursor: pointer;
    background-color: ${props => props.hoverBackground || SASStyleGuide.color.secondary};
  }

  ${media.phone`
    background-color: ${ColorPalette.background}
  `}
`;

export const BigSectionDiv = styled(SASDiv)`
  height: 500px;
  ${media.desktop`
    height: 400px;
    `}
  ${media.tablet`
    height: 350px;
    `}
  ${media.phone`
    height: 300px;
    `}
`;
export const HugeSectionDiv = styled(SASDiv)`
  max-height: 650px;
  min-height: 650px;
  padding: 0px;
  width: 100%;
  ${media.giant`min-height: 650px`}
  ${media.desktop`min-height: 600px`}
  ${media.tablet`min-height:  500px `}
  ${media.phone`min-height: 400px`}
`;
