/*
  SASStyleGuide.js
  Betterment Labs 
  Created by Matthew Peterson on 06/20/18. 
  Copyright © 2018 Betterment Labs, LLC. All rights reserved.
*/

import ColorPalette from "./ColorPalette";
import { defaultAndroidFonts } from "./defaultFonts";

const SASStyleGuide = {
  color: {
    background: ColorPalette.SOSDarkBlue,
    bodyText: ColorPalette.White,
    title: ColorPalette.MidBlue,
    secondary: ColorPalette.Red,
    tertiary: ColorPalette.LightGray,
    highlight: ColorPalette.SOSYellow,
    detailText: ColorPalette.DarkGray,
    quarternary: ColorPalette.Cream,
    black: ColorPalette.Black,
    priorityOrange: ColorPalette.priorityOrange,
    lackingPriority: ColorPalette.Teal,
    safe: ColorPalette.Green,
    BarelyGray: ColorPalette.BarelyGray
  },
  hexColor: {
    Black: "#000000", // not used
    Yellow: "#F8D269", // not used
    BrighterYellow: "#FFD645",
    Red: "#B21E27", // not used
    Purple: "#5A2154", // not used
    DarkerPurpler: "#4D154F",
    Teal: "#00B1C5",
    Cream: "#FDF3D9", // not used
    SnowWhite: "#F9FBFF", // not used
    LightGray: "#C6C8C7", // not used
    DarkGray: "#434246", // not used
    MidBlue: "#332D8C", // not used
    LightBlue: "#332D8C",
    DarkBlue: "#0D0047",
    White: "#FFFFFF"
  },
  font: {
    size: {
      finePrint: ".8em",
      tiny: "1em",
      small: "1.2em",
      mediumSmall: "1.5em",
      medium: "2em",
      mediumLarge: "2.5em",
      large: "3em",
      huge: "4.0em",
      massive: "4.5em",
      giant: "5em",
      enormous: "10em",
      unnecessary: "20em"
    },
    family: {
      helveticaNeue: "helvetica neue",
      monoFur: "MonoFur",
      chivo: "Chivo",
      axis: "Axis",
      roboto: defaultAndroidFonts.regular
    },
    weight: {
      oneHundred: "100",
      twoHunderd: "200",
      threeHunded: "300",
      fourHundred: "400",
      fiveHundred: "500",
      sixHundred: "600",
      sevenHundred: "700",
      eightHundred: "800",
      nineHundred: "900",
      light: "100",
      medium: "400",
      heavy: "700"
    },
    styles: {
      regular: defaultAndroidFonts.regular,
      black: defaultAndroidFonts.black,
      blackItalic: defaultAndroidFonts.blackItalic,
      bold: defaultAndroidFonts.bold,
      boldItalic: defaultAndroidFonts.boldItalic,
      italic: defaultAndroidFonts.italic,
      light: defaultAndroidFonts.light,
      lightItalic: defaultAndroidFonts.lightItalic
    }
  }
};
export default SASStyleGuide;
