import React from "react";
import {
  SASDiv,
  SASImage,
  SASSectionHeader,
  SASAnchor
} from "./generic/GenericStyledComponents";
import styled from "styled-components";
import SASStyleGuide from "./theming/SASStyleGuide";
import SASLanding from "./assets/images/SafeAtSchool.png";
import SASecureLanding from "./assets/images/SafeAndSecure.png";
import SASLogo from "./assets/images/S@SLogoText.png";
import SASecureLogo from "./assets/images/SASecureLogoText.png";
import SOSLogo from "./assets/images/SOSLogoText.png";
import SafeOnScene from "./assets/images/SafeOnScene.png";
import Badge from "./assets/images/BuiltByBadge.png";
function App() {
  const values = [
    {
      image: SASecureLanding,
      headerImage: SASecureLogo,
      subHeader: "Incident Management System",
      platforms: ["iOS", "Android", "Desktop"],
      link: "https://sossafeandsecure.com/"
    },{
      image: SASLanding,
      headerImage: SASLogo,
      subHeader: "Incident Management System",
      platforms: ["iOS", "Android", "Desktop"],
      link: "https://www.sossafeatschool.com"
    },
    {
      image: SafeOnScene,
      headerImage: SOSLogo,
      subHeader: "Active Shooter Safety App",
      platforms: ["iOS", "Android"],
      link: "https://www.sossafeonscene.com "
    }
  ];
  return (
    <SASDiv justifyContent='flex-start' padding='30px 0 0 0'>
      <SASDiv
        height='50%'
        flexDirection='row'
        justifyContent='space-around'
        flexWrap='wrap'
        maxWidth='1200px'
      >
        {values.map(s => {
          return (
            <SASAnchor
              style={{ minHeight: "650px", margin: "50px 25px" }}
              target='_blank'
              href={s.link}
              rel='noopener noreferrer'
            >
              <SASDiv
                width='initial'
                borderRadius='10px'
                backgroundColor={SASStyleGuide.color.bodyText}
                hoverBrightess='brightness(90%)'
                minWidth='300px'
                maxWidth='400px'
              >
                <SASImage
                  maxHeight='unset'
                  maxWidth='250px'
                  width='unset'
                  height='unset'
                  src={s.headerImage}
                />
                <SASSectionHeader
                  style={{ textDecoration: "none !important" }}
                  color={SASStyleGuide.color.background}
                  fontSize='20px'
                  padding='20px'
                  whiteSpace='pre-line'
                >
                  {s.subHeader}
                </SASSectionHeader>
                <SASImage
                  maxHeight='unset'
                  maxWidth='200px'
                  width='unset'
                  height='unset'
                  src={s.image}
                />
                <ul
                  style={{
                    minHeight: "110px",
                    display: "flex",
                    flexDirection: "column",
                    margin: "0",
                    justifyContent: "center"
                  }}
                >
                  {s.platforms.map(a => {
                    return (
                      <li
                        style={{
                          fontSize: "18px",
                          color: SASStyleGuide.color.title
                        }}
                      >
                        {a}
                      </li>
                    );
                  })}
                </ul>
                <StyledAnchor
                  style={{
                    border: `3px solid ${SASStyleGuide.color.highlight}`,
                    textDecoration: "none",
                    borderRadius: "10px",
                    color: SASStyleGuide.color.title,
                    padding: "5px 15px"
                  }}
                  href={s.link}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Learn More
                </StyledAnchor>
              </SASDiv>
            </SASAnchor>
          );
        })}
      </SASDiv>
      <StyledDiv flexDirection='column' height='20%' margin='10px 0 0 0'>
        <SASAnchor
          target='_blank'
          href={"http://www.bettermentlabs.com"}
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='100%'
          color='white'
          fontSize={SASStyleGuide.font.size.tiny}
          mobileFontSize={SASStyleGuide.font.size.tiny}
        >
          <SASImage
            src={Badge}
            height='unset'
            width='unset'
            maxWidth='200px'
            maxHeight='unset'
          />
          
        </SASAnchor>
        v1.0
      </StyledDiv>
    </SASDiv>
  );
}

export default App;

const StyledDiv = styled(SASDiv)``;

const StyledAnchor = styled.a`
  &:hover {
    transform: scale(1.01, 1.01);
  }
`;
