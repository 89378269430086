/*
  defaultFonts.js
  Betterment Labs 
  Created by Charles Major on 02/01/18. 
  Copyright © 2018 Betterment Labs, LLC. All rights reserved.

  Use :
*/

// default fonts
export const defaultAndroidFonts = {
  needsLoading: false,
  regular: "Chivo",
  black: "Chivo",
  blackItalic: "Chivo",
  bold: "Chivo",
  boldItalic: "Chivo",
  italic: "Chivo",
  light: "Chivo",
  lightItalic: "Chivo"
};

export const defaultIosFonts = {
  needsLoading: false,
  regular: "Gill Sans",
  black: "GillSans-UltraBold",
  blackItalic: "GillSans-BoldItalic",
  bold: "GillSans-Bold",
  boldItalic: "GillSans-SemiBoldItalic",
  italic: "GillSans-Italic",
  light: "GillSans-Light",
  lightItalic: "GillSans-LightItalic"
};
